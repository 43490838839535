<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
        <el-form-item label="规则名称" prop="queryParseName">
          <el-input v-model="formInline.queryParseName" placeholder="请输入规则名称"></el-input>
        </el-form-item>
        <el-form-item label="手册类型" prop="queryManualType">
          <el-select v-model="formInline.queryManualType">
            <el-option v-for="(item, index) in manualTypeList" :key="index" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="样式" prop='queryStyle'>
          <el-select v-model="formInline.queryStyle">
            <el-option v-for="(item, index) in manualStyleList" :key="index" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <el-button type="primary" icon="el-icon-plus" @click="headelAdd()">新增</el-button>
      <el-table style="width:100%" border :data="resultList" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
        <el-table-column label="手册类型" prop="manualTypeName" width="180"></el-table-column>
        <el-table-column label="规则名称" prop="name" width="190"></el-table-column>
        <el-table-column label="规则编码" prop="code" width="190"></el-table-column>
        <el-table-column label="样式模板" prop="styleName" width="180"></el-table-column>
        <el-table-column label="是否默认" prop="isDefault" width="130">
          <template slot-scope="{row}">
            <span v-if="row.isDefault === 1" style="color:red">是</span>
            <span v-if="row.isDefault !== 1">否</span>
          </template>
        </el-table-column>
        <el-table-column label="有效状态" prop="userFlag" width="100">
          <template slot-scope="{row}">
            <span v-if="row.useFlag === 1">启用</span>
            <span v-if="row.useFlag !== 1" style="color:red">禁用</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" width="100"></el-table-column>
        <el-table-column label="更新时间" prop="updatedTime" :formatter="dateFormat" width="180"></el-table-column>
        <el-table-column label="更新人" prop="updatedUserName" width="130"></el-table-column>
        <el-table-column label="操作" fixed="right" width="260">
          <template slot-scope="{row}">
            <el-button type="primary" size="mini" @click="headelCheck(row)">查看</el-button>
            <el-button type="primary" size="mini" @click="headelEdit(row)">编辑</el-button>
            <el-button type="primary" size="mini" style="background:#FF5722" @click="headelDelete(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :before-close="handleCloseCreate">
        <el-form v-if="dialogStatus === 'add' || dialogStatus === 'edit'" :model="temp" ref="temp" label-position="center" :rules="rules">
          <el-form-item label="规则名称" prop="name" :label-width="formLabelWidth">
            <el-input v-model="temp.name" placeholder="请输入规则名称"></el-input>
          </el-form-item>
          <el-form-item label="规则编码" prop="code" :label-width="formLabelWidth">
            <el-input v-model="temp.code" placeholder="请输入规则编码"></el-input>
          </el-form-item>
          <el-form-item label="手册类型" prop="manualType" :label-width="formLabelWidth">
            <el-select v-model="temp.manualType">
              <el-option v-for="(item, index) in manualTypeList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="样式" prop="styleId" :label-width="formLabelWidth">
            <el-select v-model="temp.styleId">
              <el-option v-for="(item, index) in manualStyleList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否默认" prop="isDefault" :label-width="formLabelWidth">
            <el-select v-model="temp.isDefault">
              <el-option v-for="(item, index) of defaultList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
            <el-input placeholder="请输入备注" v-model="temp.remark"></el-input>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="dialogStatus === 'add' ? addClick('temp') : editClick('temp')">立即提交</el-button>
            <el-button @click="resetForm('temp')">重置</el-button>
          </el-form-item>
        </el-form>
        <el-form v-if="dialogStatus === 'detail'" :model="temp" ref="temp" label-position="center" :rules="rules">
          <el-form-item label="项目" prop="projectId" :label-width="formLabelWidth">
            <el-select v-model="temp.projectId" readonly disabled>
              <el-option v-for="(item, index) in projectList" :key="index" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手册分类" prop="cnName" :label-width="formLabelWidth">
            <el-input v-model="temp.cnName" readonly></el-input>
          </el-form-item>
          <el-form-item label="负责人" prop="principal" :label-width="formLabelWidth">
            <el-select v-model="temp.principal" readonly disabled>
              <el-option v-for="(item, index) in principalList" :key="index" :label="item.username" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开发状态" prop="manualStatus" :label-width="formLabelWidth">
            <el-select v-model="temp.manualStatus" readonly disabled>
              <el-option v-for="(item, index) in manualList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="节点时间" prop="deadlineTime" :label-width="formLabelWidth">
            <el-input v-model="time.deadlineTime" readonly></el-input>
          </el-form-item>
          <el-form-item label="初稿时间" prop="firstDraftTime" :label-width="formLabelWidth">
            <el-input v-model="time.firstDraftTime" readonly></el-input>
          </el-form-item>
          <el-form-item label="终稿时间" prop="finalDraftTime" :label-width="formLabelWidth">
            <el-input v-model="time.finalDraftTime" readonly></el-input>
          </el-form-item>
          <el-form-item label="风险状态" prop="riskStatus" :label-width="formLabelWidth">
            <el-select v-model="temp.riskStatus" readonly disabled>
              <el-option v-for="(item, index) in riskList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { sysServerUrl, cmsServerUrl, obj } from '@/assets/js/common.js'
import Pagination from '@/components/Pagination'
import { parseData, parseSearch, parseManualType, parseManualStyle, parseUser,
 parseProject, parseAdd, parseCheck, parseEdit, parseDel } from '@/api/api.js'
import axios from 'axios'
export default {
  name: 'layoutContainer',
  components: { Pagination },
  data () {
    return {
      formInline: {
        queryParseName: '',
        queryManualType: '',
        queryStyle: ''
      },
      temp: {
        id: '',
        projectId: '',
        name: '',
        code: '',
        manualType: '',
        styleId: '',
        isDefault: '',
        remark: '',
        cnName: '',
        principal: '',
        manualStatus: '',
        riskStatus: ''
      },
      riskList: [
        { name: '无风险', code: 1 },
        { name: '低风险', code: 2 },
        { name: '中风险', code: 3 },
        { name: '高风险', code: 4 }
      ],
      manualList: [
        { name: '进行中', code: 1 },
        { name: '暂停中', code: 2 },
        { name: '已验收', code: 3 }
      ],
      defaultList: [
        { name: '是', code: 1 },
        { name: '否', code: 0 }
      ],
      time: {
        deadlineTime: '',
        firstDraftTime: '',
        finalDraftTime: ''
      },
      manualTypeList: [],
      manualStyleList: [],
      projectList: [],
      principalList: [],
      resultList: [],
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        detail: '解析规则明细',
        edit: '编辑解析规则',
        add: '新增解析规则'
      },
      formLabelWidth: '100px',
      pageSize: 10,
      currentPage: 1,
      total: 0,
      rules: {
        name: [{ required: true, message: '请输入规则名称', trigger: 'blur' }],
        code: [{ required: true, message: '请输入规则编码', trigger: 'blur' }],
        manualType: [{ required: true, message: '请选择手册类型', trigger: 'blur' }],
        styleId: [{ required: true, message: '请选择样式', trigger: 'blur' }],
        isDefault: [{ required: true, message: '请选择是否默认', trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 获取手册类型列表
    getManualTypeList () {
      parseManualType().then(res => {
        if (res.data.code === 100) {
          this.manualTypeList = res.data.data
        }
      })
    },
    // 获取手册样式列表
    getManualStyleList () {
      parseManualStyle().then(res => {
        if (res.data.code === 100) {
          this.manualStyleList = res.data.data
        }
      })
    },
    // 用户列表
    getUserList () {
      parseUser().then(res => {
        if (res.data.code === 100) {
          this.principalList = res.data.data
        }
      })
    },
    // 获取项目列表
    getProjectList () {
      parseProject().then(res => {
        if (res.data.code === 100) {
          this.projectList = res.data.data
        }
      })
    },
    dataList () {
      var params = {
        page: this.currentPage,
        limit: this.pageSize
      }
      parseData(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    handleCloseCreate () {
      this.dialogFormVisible = false
      if (this.dialogStatus === 'add') {
        this.$refs.temp.resetFields()
      }else if(this.dialogStatus === 'edit'){
        this.$refs.temp.resetFields()
      }else{

      }
    },
    // 搜索
    onSubmit () {
      var params = {
        page: this.currentPage,
        limit: this.pageSize,
        name: this.formInline.queryParseName,
        manualType: this.formInline.queryManualType,
        styleId: this.formInline.queryStyle
      }
      parseSearch(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    // 重置
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$refs[formInline].resetFields()
      }
      this.dataList()
    },
    resetTemp () {
      this.temp = {
        name: '',
        code: '',
        manualType: '',
        styleId: '',
        isDefault: '',
        remark: ''
      }
    },
    // 新增
    headelAdd () {
      this.resetTemp()
      this.dialogFormVisible = true
      this.dialogStatus = 'add'
    },
    addClick (temp) {
      this.$refs[temp].validate((valid) => {
        if (valid) {
          var params = new URLSearchParams()
          params.append('name', this.temp.name)
          params.append('code', this.temp.code)
          params.append('manualType', this.temp.manualType)
          params.append('styleId', this.temp.styleId)
          params.append('isDefault', this.temp.isDefault)
          params.append('remark', this.temp.remark)
          parseAdd(params).then(res=>{
            if (res.data.code === 100) {
              this.$message({
                message: res.data.msg,
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(err => {
            if (err !== null && err !== '' && err.responseText !== null) {
              this.$message.error('提交失败,请重试')
            }
          })
        } else {
          this.$message.error('完善信息')
        }
      })
    },
    resetForm (temp) {
      if (this.$refs[temp].resetFields() !== undefined) {
        this.$refs[temp].resetFields()
      }
    },
    // 查看
    headelCheck (row) {
      this.dialogFormVisible = true
      this.dialogStatus = 'detail'
      var params = '?id=' + row.id
      parseCheck(params).then(res => {
        if(res.data.code === 100){
          this.time = res.data.data
          this.temp = res.data.data.manual
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    // 编辑
    headelEdit (row) {
      this.resetTemp()
      this.temp = Object.assign({}, row)
      this.dialogFormVisible = true
      this.dialogStatus = 'edit'
    },
    editClick (temp) {
      this.$refs[temp].validate((valid) => {
        if (valid) {
          var params = new URLSearchParams()
          params.append('id', this.temp.id)
          params.append('name', this.temp.name)
          params.append('code', this.temp.code)
          params.append('manualType', this.temp.manualType)
          params.append('styleId', this.temp.styleId)
          params.append('isDefault', this.temp.isDefault)
          params.append('remark', this.temp.remark)
          parseEdit(params).then(res => {
            if (res.data.code === 100) {
              this.$message({
                message: res.data.msg,
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.error('完善信息')
        }
      })
    },
    // 删除
    headelDelete (row) {
      this.$confirm('确定删除该行?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(res => {
        parseDel(row.id).then(res => {
          if (res.data.code === 100) {
            this.$message({
              type: 'success',
              message: '删除成功！'
            })
            this.dataList()
          }
        })
      }).catch((error) => {
        this.$message.error('删除失败')
      })
    },
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return Y + M + D
      }
    }
  },
  mounted () {
    this.getManualTypeList()
    this.getManualStyleList()
    this.getProjectList()
    this.getUserList()
    this.dataList()
  }
}
</script>
<style>
  .el-input.is-disabled .el-input__inner::placeholder {
    color: transparent
  }
  .el-input.is-disabled .el-input__inner {
    background-color: transparent;
    border: 1px solid #DCDFE6;
    color: #606266;
    cursor: text !important;
  }
  .el-input.is-disabled .el-input__icon {
    cursor: text !important;
  }
</style>
